import React, { useState, useEffect, Fragment } from "react"
import {
  SearchContainer,
  SearchButton,
  FlatItemContainer,
  MobileContainer,
} from "./searchStyles"
import { Col, Container, Row, Accordion } from "react-bootstrap"
import { janowo } from "../../flats/flatList/list"
import { janowoEng } from "../../flats/flatList/list-eng"
import { wiczlino } from "../../flats/flatList/list2"
import { wiczlinoEng } from "../../flats/flatList/list2-eng"
import { wiczlino2 } from "../../flats/flatList/list3"
import Fuse from "fuse.js"
import posed, { PoseGroup } from "react-pose"
import { MobileButton } from "../shared/button/button"
import ModalComponent from "../shared/modal/modal"
import ShowMore from "@tedconf/react-show-more"
import { Link, injectIntl } from "gatsby-plugin-intl"
import { Link as Scroller } from "react-scroll"
import { Button } from "react-bootstrap"
// import {push} from "../../../.cache/gatsby-browser-entry";

const PoseWrapper = posed.div({
  enter: { opacity: 1, delay: ({ i }) => i * 100 },
  exit: { opacity: 0, delay: 50 },
})

var janowoNew = []
var tmpArray = {}
tmpArray["B3"] = []
tmpArray["B7"] = []
tmpArray["B8"] = []
tmpArray["B9"] = []
for (let key in janowo) {
  if (
    janowo[key]["13"] === "" &&
    (janowo[key]["1"] === "B7" ||
      janowo[key]["1"] === "B8" ||
      janowo[key]["1"] === "B9" ||
      janowo[key]["1"] === "B3")
  ) {
    tmpArray[janowo[key]["1"]].push(janowo[key])
  }
}

janowoNew = [
  ...tmpArray["B3"],
  ...tmpArray["B7"],
  ...tmpArray["B8"],
  ...tmpArray["B9"],
]

var janowoEngNew = []
tmpArray = {}
tmpArray["B7"] = []
tmpArray["B8"] = []
tmpArray["B9"] = []
for (let key in janowoEng) {
  if (
    janowoEng[key]["13"] === "" &&
    (janowoEng[key]["1"] === "B7" ||
      janowoEng[key]["1"] === "B8" ||
      janowoEng[key]["1"] === "B9")
  ) {
    tmpArray[janowoEng[key]["1"]].push(janowoEng[key])
  }
}

janowoEngNew = [...tmpArray["B7"], ...tmpArray["B8"], ...tmpArray["B9"]]

function Search({ estate, searchParams, intl }) {
  const list =
    estate === "wiczlino"
      ? intl.locale === "en"
        ? wiczlinoEng
        : wiczlino
      : estate === "wiczlino2"
      ? wiczlino2.sort((a, b) => (!a["13"] ? 1 : -1)).reverse()
      : intl.locale === "en"
      ? janowoEngNew
      : janowoNew
  // const list = estate === "wiczlino" ? (intl.locale === 'en' ? wiczlinoEng : wiczlino) : (intl.locale === 'en' ? janowoEng : janowo);

  // console.log(intl);

  const [selectValue, setSelectValue] = useState({
    building: ["", 1],
    rooms: ["", 5],
    space: ["", 12],
    floor: ["", 3],
    windows: ["", 6],
    sold: ["", 13],
  })
  // useEffect(() => {
  //   console.log(searchParams, selectValue)
  //   searchParams !== false && setSelectValue(searchParams)
  //   searchParams !== false && setSelectCategory(["1"])
  //   searchParams !== false && setFuseOptions({
  //     shouldSort: true,
  //     threshold: 0.4,
  //     location: 0,
  //     distance: 100,
  //     maxPatternLength: 32,
  //     minMatchCharLength: 1,
  //     tokenize: true,
  //     findAllMatches: true,
  //     keys: ["1"],
  //   })
  //   searchParams !== false && handleSearch()

  // }, [searchParams])

  const [selectCategory, setSelectCategory] = useState([])
  const [results, setResults] = useState(list)
  const [estateName] = useState(estate)
  const [reset, callReset] = useState()
  const [activeFilters, setActiveFilters] = useState({})
  const [activeFiltersCounter, setActiveFiltersCounter] = useState(0)

  const addID = item => {
    return localStorage.setItem("id", item)
  }

  const optionsList = {
    building:
      estateName === "wiczlino"
        ? [
            { value: "1", label: "1", id: "building" },
            { value: "2", label: "2", id: "building" },
          ]
        : estateName === "wiczlino2"
        ? [
            { value: "1", label: "1", id: "building" },
            { value: "2", label: "2", id: "building" },
          ]
        : [
            // { value: "BONE", label: "B1", id: "building" },
            // { value: "B2", label: "B2", id: "building" },
            // { value: "B3", label: "B3", id: "building" },
            // { value: "B4", label: "B4", id: "building" },
            // { value: "B5", label: "B5", id: "building" },
            // { value: "B6", label: "B6", id: "building" },
            { value: "B7", label: "B7", id: "building" },
            { value: "B8", label: "B8", id: "building" },
            { value: "B9", label: "B9", id: "building" },
            // { value: "B10", label: "B10", id: "building" },
          ],
    rooms: [
      { value: "dwa", label: "2", id: "rooms" },
      { value: "trzy", label: "3", id: "rooms" },
      { value: "cztery", label: "4", id: "rooms" },
    ],

    space: [
      {
        value: "small",
        label: estateName === "wiczlino" ? "400-450m2" : "40-49m2",
        id: "space",
      },
      {
        value: "medium",
        label: estateName === "wiczlino" ? "450-500m2" : "50-59m2",
        id: "space",
      },
      {
        value: "large",
        label: estateName === "wiczlino" ? "500-650m2" : "60-75m2",
        id: "space",
      },
      // {
      //   value: "huge",
      //   label: estateName === "wiczlino" ? "" : "60-75m2",
      //   id: "space",
      // },
    ],

    sold: [
      {
        value: "",
        label: "sold",
        id: "sold",
      },
    ],

    floor: [
      { value: "P0", label: "P0", id: "floor" },
      { value: "P1", label: "P1", id: "floor" },
      { value: "P2", label: "P2", id: "floor" },
      { value: "P3", label: "P3", id: "floor" },
    ],

    windows: [
      {
        value: `${intl.formatMessage({
          id: "offer.janowo.list.options.windows.north",
        })}`,
        label: `${intl.formatMessage({
          id: "offer.janowo.list.options.windows.north",
        })}`,
        id: "windows",
      },
      {
        value: `${intl.formatMessage({
          id: "offer.janowo.list.options.windows.south",
        })}`,
        label: `${intl.formatMessage({
          id: "offer.janowo.list.options.windows.south",
        })}`,
        id: "windows",
      },
      {
        value: `${intl.formatMessage({
          id: "offer.janowo.list.options.windows.east",
        })}`,
        label: `${intl.formatMessage({
          id: "offer.janowo.list.options.windows.east",
        })}`,
        id: "windows",
      },
      {
        value: `${intl.formatMessage({
          id: "offer.janowo.list.options.windows.west",
        })}`,
        label: `${intl.formatMessage({
          id: "offer.janowo.list.options.windows.west",
        })}`,
        id: "windows",
      },
    ],
  }

  const [fuseOptions, setFuseOptions] = useState({
    shouldSort: true,
    threshold: 0.4,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    tokenize: true,
    findAllMatches: true,
    keys: selectCategory,
  })

  const handleSelectValue = (e, numId, val, id) => {
    const checkCat = () => {
      console.log("weszlo check")
      let obTemp = Object.values(selectValue)
      obTemp.map(element => {
        element[1] == numId &&
          element[0].replace(/\s/g, "").length === 0 &&
          categoryTemp.splice(categoryTemp.indexOf(numId), 1)
      })
    }

    let categoryTemp = selectCategory
    let valueTemp = selectValue
    let value = val
    let re = new RegExp(value, "g")
    valueTemp[e.target.dataset.cat][0].includes(val)
      ? (valueTemp[e.target.dataset.cat][0] = valueTemp[
          e.target.dataset.cat
        ][0].replace(re, ""))
      : (valueTemp[e.target.dataset.cat][0] += val + " ")
    setSelectValue(valueTemp)
    !categoryTemp.includes(numId) ? categoryTemp.push(numId) : checkCat()
    setSelectCategory(categoryTemp)

    console.log(categoryTemp)

    setFuseOptions({
      shouldSort: true,
      threshold: 0.4,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      tokenize: true,
      findAllMatches: true,
      keys: categoryTemp,
    })
  }

  const handleReset = () => {
    callReset(!reset)
    setActiveFilters({})
    setActiveFiltersCounter(0)
    setTempResults(list)
    setSelectCategory([])
    setSelectValue({
      building: ["", 1],
      rooms: ["", 5],
      space: ["", 12],
      floor: ["", 3],
      windows: ["", 6],
    })
    setResults(list)
  }

  const [tempResults, setTempResults] = useState(list)

  const [showFilters, setShowFilters] = useState(false)

  const handleSearch = () => {
    let fuse = new Fuse(list, fuseOptions)
    selectCategory.forEach((elem, i) => {
      for (let key in selectValue) {
        console.log(selectValue[key][1], elem)
        if (selectValue[key][1] === parseInt(elem)) {
          setTempResults(fuse.search(selectValue[key][0]))
          fuse = new Fuse(fuse.search(selectValue[key][0]), fuseOptions)
        }
      }
    })

    selectCategory.length < 1 && setResults(list)
  }

  useEffect(() => {
    setResults(tempResults)
  }, [tempResults])

  const gtag_report_ask_conversion = () => {
    if (window.gtag_report_ask_conversion) {
      window.gtag_report_ask_conversion()
    }
  }

  return (
    <Container className="flats">
      <SearchContainer>
        {/* DESKTOP VIEW ***************************************** */}

        {estate === "janowo" ? (
          <Row className="desktop-container">
            <Col xs="12" className="no-padding desktop-list-title">
              <h1 className="no-padding">
                {intl.formatMessage({
                  id: "offer.janowo.list.title",
                })}
              </h1>
            </Col>

            <Col
              xs={estateName === "wiczlino" ? 12 : 12}
              md={estateName === "wiczlino" ? 6 : 6}
              lg="4"
              xl="2"
              className="search-button buildings"
            >
              {/* {estate === "wiczlino" ?  */}
              <Row>
                <Col
                  className="filter-title"
                  xs={estateName === "wiczlino" ? 12 : 12}
                >
                  <p>
                    {intl.formatMessage({
                      id: "offer.janowo.list.results.building",
                    })}
                  </p>
                </Col>
                {optionsList.building.map(item => (
                  <Col key={item.label} xs={estateName === "wiczlino" ? 4 : 4}>
                    <SearchButtonWrapper
                      activeFilters={activeFilters}
                      setActiveFilters={setActiveFilters}
                      activeFiltersCounter={activeFiltersCounter}
                      setActiveFiltersCounter={setActiveFiltersCounter}
                      data-cat={item.id}
                      addValue={handleSelectValue}
                      item={item}
                      num={"1"}
                      reset={reset}
                      className={item.value === "BONE" && "TESSTOWA"}
                    />
                  </Col>
                ))}
              </Row>
              {/* : "" } */}
            </Col>
            <Col className="search-button rooms" xs="12" md="6" lg="4" xl="1">
              <Row>
                {estateName === "wiczlino" || estateName === "wiczlino2" ? (
                  <div></div>
                ) : (
                  <Fragment>
                    <Col className="filter-title" xs="12">
                      <p>
                        {intl.formatMessage({
                          id: "offer.janowo.list.results.rooms",
                        })}
                      </p>
                    </Col>
                  </Fragment>
                )}
                {optionsList.rooms.map(item => (
                  <Col key={item.label} xs="6">
                    <SearchButtonWrapper
                      activeFilters={activeFilters}
                      setActiveFilters={setActiveFilters}
                      activeFiltersCounter={activeFiltersCounter}
                      setActiveFiltersCounter={setActiveFiltersCounter}
                      data-cat={item.id}
                      num={"5"}
                      addValue={handleSelectValue}
                      reset={reset}
                      item={item}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
            <Col className="search-button" xs="12" md="6" lg="4" xl="3">
              <Row>
                <Col className="filter-title" xs="12">
                  <p>
                    {estateName === "wiczlino" || estateName === "wiczlino2"
                      ? intl.formatMessage({
                          id: "offer.janowo.list.results.space2",
                        })
                      : intl.formatMessage({
                          id: "offer.janowo.list.results.space",
                        })}
                  </p>
                </Col>
                {optionsList.space.map(item => (
                  <Col key={item.label} xs="4">
                    <SearchButtonWrapper
                      activeFilters={activeFilters}
                      setActiveFilters={setActiveFilters}
                      activeFiltersCounter={activeFiltersCounter}
                      setActiveFiltersCounter={setActiveFiltersCounter}
                      data-cat={item.id}
                      item={item}
                      addValue={handleSelectValue}
                      num={"12"}
                      reset={reset}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
            <Col className="search-button floor" xs="12" md="6" xl="2">
              <Row>
                {estateName === "wiczlino" ? (
                  <div></div>
                ) : (
                  <Fragment>
                    <Col className="filter-title" xs="12">
                      <p>
                        {intl.formatMessage({
                          id: "offer.janowo.list.results.floor",
                        })}
                      </p>
                    </Col>
                  </Fragment>
                )}
                {optionsList.floor.map(item => (
                  <Col key={item.label} xs="3">
                    <SearchButtonWrapper
                      activeFilters={activeFilters}
                      setActiveFilters={setActiveFilters}
                      activeFiltersCounter={activeFiltersCounter}
                      setActiveFiltersCounter={setActiveFiltersCounter}
                      data-cat={item.id}
                      addValue={handleSelectValue}
                      num={"3"}
                      reset={reset}
                      item={item}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
            <Col className="search-button" xs="12" lg="6" xl="3">
              <Row>
                <Col className="filter-title" xs="12">
                  <p>
                    {intl.formatMessage({
                      id: "offer.janowo.list.results.windows",
                    })}
                  </p>
                </Col>
                {optionsList.windows.map(item => (
                  <Col xs="3" key={item.label}>
                    <SearchButtonWrapper
                      activeFilters={activeFilters}
                      setActiveFilters={setActiveFilters}
                      activeFiltersCounter={activeFiltersCounter}
                      setActiveFiltersCounter={setActiveFiltersCounter}
                      data-cat={item.id}
                      item={item}
                      num={"6"}
                      addValue={handleSelectValue}
                      reset={reset}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
            <Col xl="1" className="buttons-desktop">
              <MobileButton
                handleAction={handleReset}
                innerText={intl.formatMessage({
                  id: "offer.janowo.list.reset.button",
                })}
                bg="rgb(93, 93, 93)"
                color="white"
                border="rgb(93, 93, 93)"
              />
              <MobileButton
                handleAction={handleSearch}
                innerText={intl.formatMessage({
                  id: "offer.janowo.list.filter.button",
                })}
                bg="rgb(148, 200, 53) "
                color="white"
                border="rgb(148, 200, 53) "
              />
            </Col>
            <Col
              xs={estateName === "wiczlino" ? 6 : 6}
              md="5"
              xl="1"
              className="search-btn buttons-mobile filter-btn"
            >
              <MobileButton
                handleAction={handleSearch}
                innerText={intl.formatMessage({
                  id: "offer.janowo.list.filter.button",
                })}
                bg="rgb(148, 200, 53) "
                color="white"
                border="rgb(148, 200, 53) "
              />
            </Col>
            <Col className="offset-col" xs="0" sm="0" md="2" />
            <Col
              xs={estateName === "wiczlino" ? 6 : 6}
              md="5"
              xl="1"
              className="search-btn buttons-mobile reset-btn"
            >
              <MobileButton
                handleAction={handleReset}
                innerText={intl.formatMessage({
                  id: "offer.janowo.list.reset.button",
                })}
                bg="rgb(93, 93, 93)"
                color="white"
                border="rgb(93, 93, 93)"
              />
            </Col>
          </Row>
        ) : (
          <Row className="desktop-container">
            <Col xs="12" className="no-padding desktop-list-title">
              <h1 className="no-padding">
                {intl.formatMessage({
                  id: "offer.janowo.list.title",
                })}
              </h1>
            </Col>
          </Row>
        )}

        {/* MOBILE VIEW ************************ */}
        {estate === "janowo" ? (
          <Row className="mobile-container">
            <Col xs="12" className="no-padding mobile-list-title">
              <h1 className="no-padding">
                {intl.formatMessage({
                  id: "offer.janowo.list.title",
                })}
              </h1>
            </Col>
            <Col xs={12} className="search-btn no-padding">
              <ModalComponent activeFilters={activeFilters} search={true}>
                <MobileButton
                  handleAction={() => setShowFilters(!showFilters)}
                  innerText={`${intl.formatMessage({
                    id: "offer.janowo.list.filter.modal",
                  })} (${activeFiltersCounter})`}
                  bg="white"
                  color="#B0B0B0"
                  border="#B0B0B0"
                />
                <MobileContainer className="modal-filters-wrapper">
                  <Col className="search-title" xs="12">
                    <h1>
                      {intl.formatMessage({
                        id: "offer.janowo.list.filter.modal",
                      })}
                    </h1>
                  </Col>
                  <Col className="filter-title" xs={12}>
                    <p>
                      {intl.formatMessage({
                        id: "offer.janowo.list.results.building",
                      })}
                    </p>
                  </Col>
                  <Col xs={12} className="search-button buildings">
                    <Row>
                      {optionsList.building.map(item => (
                        <Col key={item.label} xs={3}>
                          <SearchButtonWrapper
                            activeFilters={activeFilters}
                            setActiveFilters={setActiveFilters}
                            activeFiltersCounter={activeFiltersCounter}
                            setActiveFiltersCounter={setActiveFiltersCounter}
                            data-cat={item.id}
                            addValue={handleSelectValue}
                            item={item}
                            num={"1"}
                            reset={reset}
                          />
                        </Col>
                      ))}
                    </Row>
                  </Col>
                  <Fragment>
                    {estateName === "wiczlino" ? (
                      <div></div>
                    ) : (
                      <Fragment>
                        <Col className="filter-title" xs="3">
                          <p>
                            {intl.formatMessage({
                              id: "offer.janowo.list.results.rooms",
                            })}
                          </p>
                        </Col>
                        <Col className="search-button rooms" xs="12">
                          <Row>
                            {optionsList.rooms.map(item => (
                              <Col key={item.label} xs="3">
                                <SearchButtonWrapper
                                  activeFilters={activeFilters}
                                  setActiveFilters={setActiveFilters}
                                  activeFiltersCounter={activeFiltersCounter}
                                  setActiveFiltersCounter={
                                    setActiveFiltersCounter
                                  }
                                  data-cat={item.id}
                                  num={"5"}
                                  addValue={handleSelectValue}
                                  reset={reset}
                                  item={item}
                                />
                              </Col>
                            ))}
                          </Row>
                        </Col>
                      </Fragment>
                    )}
                  </Fragment>

                  <Col className="filter-title" xs="12">
                    <p>
                      {estateName === "wiczlino"
                        ? intl.formatMessage({
                            id: "offer.janowo.list.results.space2",
                          })
                        : intl.formatMessage({
                            id: "offer.janowo.list.results.space2",
                          })}
                    </p>
                  </Col>
                  <Col className="search-button" xs="12">
                    <Row>
                      {optionsList.space.map(item => (
                        <Col key={item.label} xs="6">
                          <SearchButtonWrapper
                            activeFilters={activeFilters}
                            setActiveFilters={setActiveFilters}
                            activeFiltersCounter={activeFiltersCounter}
                            setActiveFiltersCounter={setActiveFiltersCounter}
                            data-cat={item.id}
                            item={item}
                            addValue={handleSelectValue}
                            num={"12"}
                            reset={reset}
                          />
                        </Col>
                      ))}
                    </Row>
                  </Col>
                  {estateName === "wiczlino" ? (
                    <div></div>
                  ) : (
                    <Fragment>
                      <Col className="filter-title" xs="3">
                        <p>
                          {intl.formatMessage({
                            id: "offer.janowo.list.results.floor",
                          })}
                        </p>
                      </Col>

                      <Col className="search-button floor" xs="12">
                        <Row>
                          {optionsList.floor.map(item => (
                            <Col key={item.label} xs="3">
                              <SearchButtonWrapper
                                activeFilters={activeFilters}
                                setActiveFilters={setActiveFilters}
                                activeFiltersCounter={activeFiltersCounter}
                                setActiveFiltersCounter={
                                  setActiveFiltersCounter
                                }
                                data-cat={item.id}
                                addValue={handleSelectValue}
                                num={"3"}
                                reset={reset}
                                item={item}
                              />
                            </Col>
                          ))}
                        </Row>
                      </Col>
                    </Fragment>
                  )}
                  <Col className="filter-title" xs="12">
                    <p>
                      {intl.formatMessage({
                        id: "offer.janowo.list.results.windows",
                      })}
                    </p>
                  </Col>
                  <Col className="search-button" xs="12">
                    <Row>
                      {optionsList.windows.map(item => (
                        <Col key={item.label} xs="6">
                          <SearchButtonWrapper
                            activeFilters={activeFilters}
                            setActiveFilters={setActiveFilters}
                            activeFiltersCounter={activeFiltersCounter}
                            setActiveFiltersCounter={setActiveFiltersCounter}
                            data-cat={item.id}
                            item={item}
                            num={"6"}
                            addValue={handleSelectValue}
                            reset={reset}
                          />
                        </Col>
                      ))}
                    </Row>
                  </Col>
                  {/* <Col xs="12" className="offset-bottom"></Col> */}
                </MobileContainer>
                <Col xs={12} className="search-btn reset-btn">
                  <MobileButton
                    handleAction={() => handleSearch()}
                    innerText={intl.formatMessage({
                      id: "offer.janowo.list.filter.button",
                    })}
                    bg="rgb(148, 200, 53)"
                    color="white"
                    border="rgb(148, 200, 53)"
                  />
                </Col>
                <Col xs={12} className="search-btn reset-btn">
                  <MobileButton
                    handleAction={() => handleReset()}
                    innerText={intl.formatMessage({
                      id: "offer.janowo.list.reset.button",
                    })}
                    bg="rgb(93, 93, 93)"
                    color="white"
                    border="rgb(93, 93, 93)"
                  />
                </Col>
              </ModalComponent>
            </Col>
          </Row>
        ) : (
          <Row className="mobile-container">
            <Col xs="12" className="no-padding desktop-list-title">
              <h1 className="no-padding">
                {intl.formatMessage({
                  id: "offer.janowo.list.title",
                })}
              </h1>
            </Col>
          </Row>
        )}

        <div className="container-border">
          <h2>
            {results.length === list.length
              ? intl.formatMessage({
                  id: "offer.janowo.list.results.number.all",
                })
              : intl.formatMessage({
                  id: "offer.janowo.list.results.number",
                })}{" "}
            ({results.length}){" "}
            {results &&
              results.length < 1 &&
              intl.formatMessage({
                id: "offer.janowo.list.results.changeFilters",
              })}
          </h2>
          {estate === "wiczlino" || estate === "wiczlino2" ? (
            <Row className="legend-info wicz">
              <Col xs="2">
                {intl.formatMessage({
                  id: "offer.janowo.list.results.id",
                })}
              </Col>
              <Col xs="2">
                {intl.formatMessage({
                  id: "offer.janowo.list.results.space",
                })}
              </Col>
              <Col xs="2">
                {intl.formatMessage({
                  id: "offer.janowo.list.results.space2",
                })}
              </Col>
              <Col xs="2">
                {intl.formatMessage({
                  id: "offer.janowo.list.results.term",
                })}
              </Col>
              <Col xs="3">
                <Row style={{padding: 0}} className={"text-center"}>
                  <Col style={{padding: 0}}>3D</Col>
                  <Col style={{padding: 0}}>PDF</Col>
                  <Col style={{padding: 0}}>CENA</Col>
                </Row>
              </Col>
            </Row>
          ) : (
            <Row className="legend-info">
              <Col xl="2">
                {intl.formatMessage({
                  id: "offer.janowo.list.results.id",
                })}
              </Col>
              <Col xl="1">
                {intl.formatMessage({
                  id: "offer.janowo.list.results.building",
                })}
              </Col>
              <Col xl="1">
                {intl.formatMessage({
                  id: "offer.janowo.list.results.floor",
                })}
              </Col>
              <Col xl="1">
                {intl.formatMessage({
                  id: "offer.janowo.list.results.space",
                })}
              </Col>
              <Col xl="1">
                {intl.formatMessage({
                  id: "offer.janowo.list.results.rooms",
                })}
              </Col>
              <Col xl="2">
                {intl.formatMessage({
                  id: "offer.janowo.list.results.windows",
                })}
              </Col>
              <Col xl="2">
                {intl.formatMessage({
                  id: "offer.janowo.list.results.term",
                })}
              </Col>
              <Col xl="2">

              </Col>
            </Row>
          )}
          <div
            style={{
              height: results.length < 1 && "0",
              overflow: "hidden",
              marginBottom: "50px",
            }}
          >
            {results && results.length > 0 ? (
              <PoseGroup>
                <ShowMore items={results} by={10} key="1">
                  {({ current, onMore }) => (
                    <Fragment>
                      {current.map((item, i) => (
                        <PoseWrapper key={i} i={i}>
                          <FlatItemContainer>
                            <Fragment>
                              <Accordion
                                className={`${item[13] === "" ? "" : "sold"}`}
                                defaultActiveKey=""
                              >
                                <div>
                                  <Accordion.Toggle as={"div"} eventKey="0">
                                    <div className={`mobile-results `}>
                                      <Col
                                        xs="12"
                                        className="main-results no-padding"
                                      >
                                        <div className="results-item ">
                                          {item[0]}
                                        </div>
                                        <div className="results-item">
                                          {item[3]}
                                        </div>
                                        <div className="results-item id-item">
                                          {item[4]}m<sup>2</sup>
                                        </div>

                                        {/*<div className="results-item">*/}
                                        {/*  {item[5] === "dwa"*/}
                                        {/*    ? "2"*/}
                                        {/*    : item[5] === "trzy"*/}
                                        {/*      ? "3"*/}
                                        {/*      : "4"}{" "}*/}
                                        {/*        pok.*/}
                                        {/*</div>*/}
                                        {/* )} */}

                                        {/*<div className="results-item">*/}
                                        {/*  {estate === "wiczlino" || estate === "wiczlino2"*/}
                                        {/*    ? "B"*/}
                                        {/*    : "Budynek "}*/}
                                        {/*  {item[1] === "BONE" ? "B1" : item[1]}*/}
                                        {/*</div>*/}
                                      </Col>
                                    </div>

                                    <div
                                      className={`desktop-results ${
                                        item[13] === "" ? "" : "sold"
                                      }`}
                                    >
                                      <Row>
                                        <Col
                                          xs="2"
                                          className="no-padding table-result-item"
                                        >
                                          <div className="results-item  id-item">
                                            {item[0]}
                                          </div>
                                        </Col>
                                        {estate === "janowo" ? (
                                          <Col
                                            xs="1"
                                            className="table-result-item"
                                          >
                                            <div className="results-item ">
                                              {item[1] === "BONE"
                                                ? "B1"
                                                : item[1]}
                                            </div>
                                          </Col>
                                        ) : (
                                          ""
                                        )}
                                        <Col
                                          xs={estate === "janowo" ? "1" : "2"}
                                          className="table-result-item"
                                        >
                                          {/* )} */}
                                          <div className="results-item ">
                                            {item[3]}
                                          </div>
                                        </Col>

                                        <Col
                                          className="table-result-item"
                                          xs={estate === "janowo" ? "1" : "2"}
                                        >
                                          <div className="results-item ">
                                            {item[4]}m<sup>2</sup>
                                          </div>
                                        </Col>
                                        {estate === "janowo" ? (
                                          <Col
                                            xs="1"
                                            className="table-result-item"
                                          >
                                            <div className="results-item ">
                                              {item[5] === "dwa"
                                                ? "2"
                                                : item[5] === "trzy"
                                                ? "3"
                                                : "4"}{" "}
                                              {intl.formatMessage({
                                                id:
                                                  "offer.janowo.list.options.rooms.short",
                                              })}
                                            </div>
                                          </Col>
                                        ) : (
                                          ""
                                        )}
                                        {estate === "wiczlino" ||
                                        estate === "wiczlino2" ? (
                                          ""
                                        ) : (
                                          <Col
                                            className="no-padding table-result-item sides-mobile"
                                            xs="2"
                                            xl="2"
                                          >
                                            <div className="results-item  ">
                                              <div className="sides">
                                                {item[6]
                                                  .split(",")
                                                  .map(item => (
                                                    <div
                                                      key={`${item +
                                                        Math.random()}`}
                                                    >
                                                      {item}
                                                    </div>
                                                  ))}
                                              </div>
                                            </div>
                                          </Col>
                                        )}

                                        <Col
                                          className="no-padding date-mobile table-result-item"
                                          xs="2"
                                          xl={estate === "janowo" ? "2" : "2"}
                                        >
                                          <div className="results-item  date">
                                            <span></span>{" "}
                                            {item[13] === ""
                                              ? item[7]
                                              : item[7]}
                                          </div>
                                        </Col>
                                        <Col
                                          className="no-padding table-result-item"
                                          xs={estate === "janowo" ? "2" : "3"}
                                        >
                                          <Row>
                                            <Col
                                              className="results-item details options"
                                            >
                                              <div
                                                className="results-item"
                                                style={{
                                                  margin: 0,
                                                  padding: 0,
                                                  width: "50%",
                                                }}
                                              >
                                                {item.tour ? (
                                                  <ModalComponent
                                                    className={"full-modal"}
                                                  >
                                                    <MobileButton
                                                      handleAction={() => {
                                                        // setActiveTour(i)
                                                      }}
                                                      bg="#ddd"
                                                      border="transparent"
                                                      arrow={false}
                                                      smallArrow={false}
                                                    >
                                                      {/*<img
                                                        src={img360}
                                                        alt={"360"}
                                                        width={32}
                                                        height={32}
                                                      />*/}
                                                      Spacer 3d
                                                    </MobileButton>
                                                    <iframe
                                                      src={item.tour}
                                                      width={"100%"}
                                                      height={
                                                        typeof window !==
                                                        "undefined"
                                                          ? `${window.innerHeight *
                                                              0.9}px`
                                                          : "100%"
                                                      }
                                                    />
                                                  </ModalComponent>
                                                ) : (
                                                  <MobileButton
                                                    bg="#eee"
                                                    color="black"
                                                    border="transparent"
                                                    arrow={false}
                                                    smallArrow={false}
                                                    disabled
                                                  >
                                                    Spacer 3d
                                                  </MobileButton>
                                                )}
                                              </div>
                                              {/*</Col>*/}
                                              {/*<Col className="results-item table-result-item details">*/}
                                              <div
                                                className={
                                                  "results-item details options"
                                                }
                                              >
                                                <div
                                                  className="results-item"
                                                  style={{ width: "50%" }}
                                                >
                                                  {item[11] ? (
                                                    <a
                                                      className="results-item"
                                                      href={item[11]}
                                                      target={"_blank"}
                                                      rel="noopener noreferrer"
                                                    >
                                                      <MobileButton
                                                        handleAction={() =>
                                                          item
                                                        }
                                                        innerText={intl.formatMessage(
                                                          {
                                                            id:
                                                              "offer.janowo.list.options.card",
                                                          }
                                                        )}
                                                        bg="#5D5D5D"
                                                        color="white"
                                                        border="transparent"
                                                        arrow={false}
                                                        smallArrow={false}
                                                      />
                                                    </a>
                                                  ) : (
                                                    <MobileButton
                                                      innerText={intl.formatMessage(
                                                        {
                                                          id:
                                                            "offer.janowo.list.options.card",
                                                        }
                                                      )}
                                                      bg="#eee"
                                                      color="black"
                                                      border="transparent"
                                                      arrow={false}
                                                      smallArrow={false}
                                                      disabled
                                                    />
                                                  )}
                                                </div>
                                                {/*</Col>*/}
                                                {/*<Col style={{ margin: "auto" }}>*/}
                                                <div
                                                  className="results-item"
                                                  onClick={() => addID(item[0])}
                                                  style={{
                                                    margin: 0,
                                                    padding: 0,
                                                    width: "50%",
                                                  }}
                                                >
                                                  {item[13] === "" ? (
                                                    <MobileButton
                                                      handleAction={() => {
                                                        document.getElementById(
                                                          "myModal"
                                                        ).style = {
                                                          display: "block",
                                                        }
                                                        document.getElementsByClassName(
                                                          "code"
                                                        )[0].innerText = item[0]
                                                        document.getElementById(
                                                          "projectName"
                                                        ).value = "puck"
                                                        gtag_report_ask_conversion()
                                                      }}
                                                      innerText={
                                                        item[13] === ""
                                                          ? `${intl.formatMessage(
                                                              {
                                                                id:
                                                                  "offer.janowo.list.options.askForPrice",
                                                              }
                                                            )}`
                                                          : `${intl.formatMessage(
                                                              {
                                                                id:
                                                                  "offer.janowo.list.options.sold",
                                                              }
                                                            )}`
                                                      }
                                                      bg={
                                                        item[13] !== ""
                                                          ? "transparent"
                                                          : "#94C835"
                                                      }
                                                      color={
                                                        item[13] === ""
                                                          ? "white"
                                                          : "black"
                                                      }
                                                      border={"transparent"}
                                                      arrow={false}
                                                      smallArrow={false}
                                                    />
                                                  ) : (
                                                    <MobileButton
                                                      className={"m-0"}
                                                      innerText={intl.formatMessage(
                                                        {
                                                          id:
                                                            "offer.janowo.list.options.sold",
                                                        }
                                                      )}
                                                      border={"transparent"}
                                                      arrow={false}
                                                      bg={"#dadada"}
                                                    />
                                                  )}
                                                </div>
                                              </div>
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Accordion.Toggle>
                                  <div className={"mobile-only"}>
                                    <Accordion.Collapse eventKey="0">
                                      <Row>
                                        {estate === "wiczlino" ||
                                        estate === "wiczlino2" ? (
                                          ""
                                        ) : (
                                          <Col
                                            className="no-padding sides-desktop"
                                            xs="12"
                                          >
                                            <div className="results-item ">
                                              <div className="sides">
                                                {item[6]
                                                  .split(",")
                                                  .map(item => (
                                                    <div
                                                      key={`${item +
                                                        Math.random()}`}
                                                    >
                                                      {item}
                                                    </div>
                                                  ))}
                                              </div>
                                            </div>
                                          </Col>
                                        )}

                                        <Col
                                          className="no-padding date-desktop"
                                          xs="12"
                                        >
                                          <div className="results-item date">
                                            <span></span>{" "}
                                            {item[13] === "" && item[7]}
                                          </div>
                                        </Col>
                                        <Col
                                          xs="0"
                                          sm="0"
                                          md="0"
                                          // lg={item[13] === "" ? "6" : "8"}
                                          lg={"2"}
                                          xl="4"
                                          className="btns-offset"
                                        ></Col>
                                        <Col
                                          className="no-padding options-btn"
                                          xs="12"
                                          sm="12"
                                          md="12"
                                          lg="3"
                                          xl="2"
                                        >
                                          {item.tour && (
                                            <div className="results-item">
                                              <ModalComponent
                                                className={"full-modal"}
                                              >
                                                <MobileButton
                                                  handleAction={() => {
                                                    // setActiveTour(i)
                                                  }}
                                                  innerText={intl.formatMessage(
                                                    {
                                                      id:
                                                        "offer.janowo.list.options.tour",
                                                    }
                                                  )}
                                                  bg="#eaeaea"
                                                  border="#eaeaea"
                                                  arrow={true}
                                                  smallArrow={true}
                                                />
                                                <iframe
                                                  src={item.tour}
                                                  width={"100%"}
                                                  height={
                                                    typeof window !==
                                                    "undefined"
                                                      ? `${window.innerHeight *
                                                          0.9}px`
                                                      : "100%"
                                                  }
                                                />
                                              </ModalComponent>
                                            </div>
                                          )}
                                        </Col>
                                        <Col
                                          className="no-padding options-btn"
                                          xs="12"
                                          sm="12"
                                          md="12"
                                          lg="3"
                                          xl="2"
                                        >
                                          <div
                                            className="results-item"
                                            // href={"budynek"}
                                          >
                                            <Scroller
                                              activeClass="active"
                                              to={"localization"}
                                              spy={true}
                                              smooth={true}
                                              offset={50}
                                              duration={500}
                                            >
                                              <MobileButton
                                                handleAction={() => item}
                                                innerText={intl.formatMessage({
                                                  id:
                                                    "offer.janowo.list.options.localization",
                                                })}
                                                bg="#DADADA"
                                                border="#DADADA"
                                                arrow={true}
                                                smallArrow={true}
                                              />
                                            </Scroller>
                                          </div>
                                        </Col>
                                        <Col
                                          className="no-padding options-btn"
                                          xs="12"
                                          sm="12"
                                          md="12"
                                          lg="3"
                                          xl="2"
                                        >
                                          <a
                                            className="results-item"
                                            href={item[11]}
                                            target={"_blank"}
                                            rel="noopener noreferrer"
                                          >
                                            <MobileButton
                                              handleAction={() => item}
                                              innerText={intl.formatMessage({
                                                id:
                                                  "offer.janowo.list.options.card",
                                              })}
                                              bg="#5D5D5D"
                                              color="white"
                                              border="#5D5D5D"
                                              arrow={true}
                                              smallArrow={true}
                                            />
                                          </a>
                                        </Col>
                                        <Col
                                          className={`mobile-results mobile-results-button no-padding options-btn ${
                                            item[13] === "" ? "" : "sold"
                                          }`}
                                          xs="12"
                                          sm="12"
                                          md="12"
                                          lg="3"
                                          xl="2"
                                        >
                                          <div
                                            className="results-item"
                                            onClick={() => addID(item[0])}
                                          >
                                            <MobileButton
                                              handleAction={() => {
                                                document.getElementById(
                                                  "myModal"
                                                ).style = { display: "block" }
                                                document.getElementsByClassName(
                                                  "code"
                                                )[0].innerText = item[0]
                                                document.getElementById(
                                                  "projectName"
                                                ).value = estate
                                                gtag_report_ask_conversion()
                                              }}
                                              innerText={
                                                item[13] === ""
                                                  ? intl.formatMessage({
                                                      id:
                                                        "offer.janowo.list.options.askForPrice",
                                                    })
                                                  : intl.formatMessage({
                                                      id:
                                                        "offer.janowo.list.options.askForPrice",
                                                    })
                                              }
                                              bg={"#94c835"}
                                              color="white"
                                              border="white"
                                              arrow={true}
                                              border={"#94c835"}
                                              smallArrow={true}
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                    </Accordion.Collapse>
                                  </div>
                                </div>
                              </Accordion>
                            </Fragment>
                          </FlatItemContainer>
                        </PoseWrapper>
                      ))}
                      <Row>
                        <Col className="no-padding show-more" xs="12">
                          {onMore ? (
                            <MobileButton
                              innerText={intl.formatMessage({
                                id: "offer.janowo.list.results.more",
                              })}
                              bg="#94C835"
                              color="white"
                              border="#94C835"
                              arrow={false}
                              disabled={!onMore}
                              handleAction={() => {
                                if (!!onMore) onMore()
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </Col>
                      </Row>
                    </Fragment>
                  )}
                </ShowMore>
              </PoseGroup>
            ) : (
              <div key="brak"></div>
            )}
            {estate === "janowo" ? (
              <Row>
                <Col
                  className="no-padding"
                  xs="12"
                  style={{ textAlign: "center", margin: "auto" }}
                >
                  Posiadamy{" "}
                  <strong>
                    miejsca postojowe w hali garażowej, miejsca zewnętrzne
                  </strong>{" "}
                  oraz <strong>komórki lokatorskie</strong> w pierwszym etapie
                  inwestycji Janowo Park.
                  <br />
                  Zainteresowanych prosimy o kontakt z biurem sprzedaży.
                </Col>
              </Row>
            ) : (
              ""
            )}
          </div>
        </div>
        <Row>
          {results && results.length < 1 && (
            <Col xs="12" className="no-results">
              <div>
                <Fragment></Fragment>
              </div>
            </Col>
          )}
        </Row>
      </SearchContainer>
    </Container>
  )
}

export function SearchButtonWrapper({
  item,
  addValue,
  num,
  reset,
  setActiveFilters,
  activeFilters,
  activeFiltersCounter,
  setActiveFiltersCounter,
}) {
  useEffect(() => {}, [reset])

  return (
    <Button
      className={
        activeFilters.hasOwnProperty(item.value)
          ? `search-filter active ${item.label}`
          : `search-filter ${item.label}`
      }
      variant={false}
      disabled={
        item.label === "B1" ||
        item.label === "B3" ||
        item.label === "B2" ||
        item.label === "B4" ||
        item.label === "B5" ||
        item.label === "B6" ||
        item.label === "B10"
          ? true
          : false
      }
      onClick={e => {
        addValue(e, num, item.value, item.id)
        setActiveFiltersCounter(
          e.target.className.includes("active")
            ? (activeFiltersCounter -= 1)
            : (activeFiltersCounter += 1)
        )
        setActiveFilters(
          activeFilters && activeFilters.hasOwnProperty(item.value)
            ? () => {
                delete activeFilters[item.value]
                return activeFilters
              }
            : { ...activeFilters, [item.value]: item.value }
        )
      }}
      data-cat={item.id}
    >
      {item.label}
    </Button>
  )
}

export default injectIntl(Search)
